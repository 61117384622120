<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2 class="txtL">커뮤니티 등록</h2>
				<a @click="commnunityList()" class="btnBack">이전 페이지 이동</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- boxWhite -->
		<div class="boxWhite">
			<div class="inner">
				<!-- inputTable -->
				<div class="inputTable v2">
					<table>
						<caption>
							카테고리, 제목, 내용, 이미지 항목으로 구성된 커뮤니티 수정 표입니다.
						</caption>
						<colgroup>
							<col style="width:110px" />
							<col style="width:auto" />
						</colgroup>
						<tbody>
							<tr class="fN">
								<th scope="row">카테고리</th>
								<td>
									<input
										type="text"
										title="카테고리"
										placeholder="카테고리를 입력해주세요."
										:value="categoryName"
										readonly
									/>
								</td>
							</tr>
							<tr class="fN">
								<th scope="row">제목 <span>*</span></th>
								<td>
									<input
										type="text"
										title="제목"
										placeholder="제목을 입력해주세요."
										required="required"
										v-model="title"
										maxlength="254"
									/>
								</td>
							</tr>
							<tr class="fN">
								<th scope="row">내용 <span>*</span></th>
								<td>
									<textarea
										title="내용"
										required="required"
										placeholder="내용을 입력해주세요."
										v-model="content"
										maxlength="10000"
										style="min-height: 150px;"
									></textarea>
								</td>
							</tr>
							<tr>
								<th scope="row" class="mNone">이미지</th>
								<td>
									<!-- file -->
									<div class="file">
										<input
											type="file"
											title="이미지 업로드"
											required="required"
											id="image"
											@change="onImageUpload"
											accept="image/*"
											multiple
										/>
										<span>이미지 업로드</span>
									</div>

									<span class="checkbox" v-if="checkedVal()">
										<input type="checkbox" id="chk" v-model="checkedValues" />
										<label for="chk01">원본으로 올리기</label>
									</span>
									<!--// file -->
									<!-- imgFileList -->
									<ul class="imgFileList v2">
										<li v-for="(image, index) in images" :key="index">
											<div class="imgArea"><img :src="imagesPreview[index]" alt="" /></div>
											<a @click="onImageDelete(index)" class="btnDel">삭제</a>
										</li>
									</ul>
									<!--// imgFileList -->
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!--// inputTable -->
				<!-- inputTable -->
				<div class="inputTable">
					<table>
						<caption>
							첨부파일 항목으로 구성된 커뮤니티 수정 표입니다.
						</caption>
						<colgroup>
							<col style="width:110px" />
							<col style="width:auto" />
						</colgroup>
						<tbody>
							<tr>
								<th scope="row" class="mNone">첨부파일</th>
								<td>
									<!-- attaArea -->
									<div class="attaArea">
										<p v-for="(file, index) in files" :key="index">
											<a>{{ filesPreview[index] }}</a
											><a @click="onFileDelete(index)" class="btnDel">삭제</a>
										</p>
									</div>
									<!--// attaArea -->
									<!-- file -->
									<div class="file">
										<input
											type="file"
											title="첨부파일 업로드"
											required="required"
											id="file"
											@change="onFileUpload"
											multiple
										/>
										<span>첨부파일 업로드</span>
									</div>
									<!--// file -->
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!--// inputTable -->
			</div>
		</div>
		<!--// boxWhite -->
		<!-- inner -->
		<div class="inner">
			<!-- btnBottom -->
			<div class="btnBottom">
				<button type="button" class="btn" @click="onUpload()" :disabled="!saveBtn">저장하기</button>
			</div>
			<!--// btnBottom -->
		</div>
		<!--// inner -->
	</div>
	<!--// container -->
</template>
<script>
// eslint-disable-next-line no-unused-vars
import post from '@/api/post';
import file from '@/api/file';
import { showSpinner, hideSpinner, isImage, hideMenu, overFileSize } from '@/utils/cmm.js';

export default {
	created() {
		if (!this.category) {
			this.commnunityList();
			return false;
		}
		hideMenu();
	},
	data() {
		return {
			title: '',
			content: '',
			images: [],
			imagesPreview: [],
			files: [],
			filesPreview: [],
			id: this.$route.params.id,
			category: this.$route.params.category,
			categoryName: this.$route.params.categoryName,
			searchKeyword: this.$route.params.searchKeyword,
			orderBy: this.$route.params.orderBy,
			checkedValues: false,
		};
	},
	computed: {
		saveBtn: {
			get() {
				return this.title.length > 0 && this.content.length > 0;
			},
			set() {},
		},
	},
	methods: {
		commnunityList() {
			this.$router.push({
				name: 'communityList',
				params: { category: this.category, searchKeyword: this.searchKeyword, orderBy: this.orderBy },
			});
		},
		commnunityDetail(id) {
			this.$router.push({
				name: 'communityDetail',
				params: { id: id, searchKeyword: this.searchKeyword, orderBy: this.orderBy },
			});
		},
		async onImageUpload({ target }) {
			if (!(target && target.files && target.files.length)) return;
			const files = [];
			files.push.apply(files, target.files);
			document.getElementById('image').value = '';
			for (let i in files) {
				if (!isImage(files[i])) {
					return this.$alert(`이미지는 png, jpg, jpeg, bmp의 확장자 파일만 올려주세요.`);
				}
			}
			if (overFileSize(this.images, files, 0)) {
				return this.$alert(`이미지 파일의 총합은 100MB를 초과할 수 없습니다.`);
			}
			this.imagesPreview = [];
			this.images = this.images.concat(files);
			for (var index in this.images) {
				this.imagesPreview[index] = URL.createObjectURL(this.images[index]);
			}
		},
		async onImageDelete(id) {
			this.images.splice(id, 1);
			this.imagesPreview = [];
			for (var index in this.images) {
				this.imagesPreview[index] = URL.createObjectURL(this.images[index]);
			}
		},
		async onFileUpload({ target }) {
			if (!(target && target.files && target.files.length)) return;
			const files = [];
			files.push.apply(files, target.files);
			document.getElementById('file').value = '';
			if (overFileSize(this.images, files, 0)) {
				return this.$alert(`첨부 파일의 총합은 100MB를 초과할 수 없습니다.`);
			}
			this.filesPreview = [];
			this.files = this.files.concat(files);
			for (var index in this.files) {
				this.filesPreview[index] = this.files[index].name;
			}
		},
		async onFileDelete(id) {
			this.files.splice(id, 1);
			this.filesPreview = [];
			for (var index in this.files) {
				this.filesPreview[index] = this.files[index].name;
			}
		},
		async onUpload() {
			let params = {
				category: this.category,
				title: this.title.trim(),
				content: this.content,
			};
			try {
				showSpinner();
				if (this.images.length > 0) {
					let imageRes = await file.upload(this.images, this.checkedValues);
					params.imageInfos = imageRes.data;
				}

				if (this.files.length > 0) {
					let fileRes = await file.upload(this.files, true);
					params.fileInfos = fileRes.data;
				}
				await post.create(params);
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
			this.commnunityList();
		},
		checkedVal() {
			// this.checkedValues = false;
			return !(Array.isArray(this.images) && this.images == 0);
		},
	},
};
</script>
